/**
 * @file changelog.jsx
 * @description Changelog page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import { Link, useLocation } from "react-router-dom";
import Meta from "../components/Meta";
import Hero from "../components/hero";
import FullheightHero from "../components/fullheightHero";

/**
 * @function ChangelogPage
 * @description Changelog page.
 * @returns {JSX.Element} Changelog page.
 */

function ChangelogPage() {
  const app = useLocation().pathname.split("/")[2];

  if (app !== "light" && app !== "dark") {
    return (
      <>
        <Meta
          title="404"
          description="The page you are looking for does not exist."
          noindex={true}
        />
        <FullheightHero
          title="404"
          subtitle="The page you are looking for does not exist."
          buttonText="Go back to the homepage"
          buttonLink="/"
          color="is-danger"
        />
      </>
    );
  } else {
    if (app === "light") {
      return (
        <>
          <Meta
            title="Changelog"
            description="Get the latest news about glive: Light."
          />
          <Hero
            title="Changelog"
            subtitle="Get the latest news about glive: Light."
          />
          <div className="section">
            <div className="tabs is-centered">
              <ul>
                <li {...(app === "light" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/light">Light</Link>
                </li>
                <li {...(app === "dark" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/dark">Dark</Link>
                </li>
              </ul>
            </div>
            <div className="container">
              <div className="timeline is-centered">
                <header className="timeline-header">
                  <span className="tag is-medium is-primary">Version 1.0.0</span>
                </header>
              </div>
            </div>
          </div>
        </>
      );
    } else if (app === "dark") {
      return (
        <>
          <Meta
            title="Changelog"
            description="Get the latest news about glive: Dark."
          />
          <Hero
            title="Changelog"
            subtitle="Get the latest news about glive: Dark."
          />
          <div className="section">
            <div className="tabs is-centered">
              <ul>
                <li {...(app === "light" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/light">Light</Link>
                </li>
                <li {...(app === "dark" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/dark">Dark</Link>
                </li>
              </ul>
            </div>
            <div className="container">
              <div className="timeline is-centered">
                <header className="timeline-header">
                  <span className="tag is-medium is-primary">Version 1.0.0</span>
                </header>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default ChangelogPage;
