/**
 * @file impressions.jsx
 * @description Impressions component.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React, { useState, useEffect } from "react";
import { Client, Storage } from "appwrite";

/**
 * @function Impressions
 * @throws {Error} If API endpoint or project ID is not set or if the API call fails.
 * @returns {JSX.Element} Impressions component.
 */

function Impressions(props) {
  const client = new Client();
  const storage = new Storage(client);

  client
    .setEndpoint(process.env.REACT_APP_APPWRITE_ENDPOINT) // Your API Endpoint
    .setProject(process.env.REACT_APP_APPWRITE_PROJECT);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (props.mode === "light") {
      setImages([
        {
          fileID: "screenshot_1_light",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_2_light",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_3_light",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_4_light",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_5_light",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_6_light",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_7_light",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_8_light",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_9_light",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_10_light",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_11_light",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_12_light",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_13_light",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_14_light",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_15_light",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_16_light",
          alt: "",
          text: "",
        },
      ]);
    } else if (props.mode === "dark") {
      setImages([
        {
          fileID: "screenshot_1_dark",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_2_dark",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_3_dark",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_4_dark",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_5_dark",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_6_dark",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_7_dark",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_8_dark",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_9_dark",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_10_dark",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_11_dark",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_12_dark",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_13_dark",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_14_dark",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_15_dark",
          alt: "",
          text: "",
        },
        {
          fileID: "screenshot_16_dark",
          alt: "",
          text: "",
        },
      ]);
    } else {
      throw new Error("Invalid mode");
    }
  }, [props.mode]);

  return (
    <div className="section">
      <h2 className="title is-2 has-text-centered">Impressions</h2>
      <h3 className="subtitle is-4 has-text-centered">
        A few impressions of the {props.mode} version of “glive”.
      </h3>
      <br />
      <div className="columns is-multiline">
        {images.map((image) => (
          <div className="column is-half" key={image.fileID}>
            <div className="card">
              <div className="card-image">
                <a
                  href={
                    "https://api.felix-d1strict.de/v1/storage/buckets/63f9d03c4df58fa55a75/files/" +
                    image.fileID +
                    "/view?project=63cd3e953da71c7c7b80"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <figure className="image is-4by3">
                    <img
                      src={storage.getFilePreview(
                        "63f9d03c4df58fa55a75",
                        image.fileID,
                        800,
                        600,
                        "center",
                        70,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        "webp",
                      )}
                      alt={image.alt ? image.alt : ""}
                      width="800"
                      height="600"
                      loading="lazy"
                    />
                  </figure>
                </a>
              </div>
              {image.text && (
                <div className="card-content">
                  <div className="content">{image.text}</div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Impressions;
