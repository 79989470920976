/**
 * @file dark.jsx
 * @description Dark home page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import { Link } from "react-router-dom";
import Meta from "../components/Meta";
import MediumHero from "../components/mediumHero";
import Impressions from "../components/impressions";
import Testimonials from "../components/testimonials";

/**
 * @function DarkPage
 * @returns {JSX.Element} Dark home page.
 */

function DarkPage() {
  return (
    <>
      <Meta
        title="Dark Version"
        description="Dark version of the glive style."
      />
      <MediumHero
        title="glive (Dark)"
        subtitle="Dark version of the glive style."
        buttonText="Download"
        buttonLink="/download/dark"
      />
      <div className="section">
        <div className="container">
          <article className="message is-light">
            <div className="message-header">
              <p>Light Mode</p>
            </div>
            <div className="message-body">
              <p>
                If you prefer a light style, you can download the light version
                of “glive” here:
              </p>
              <br />
              <Link to="/light" className="button is-primary">
                Download
              </Link>
            </div>
          </article>
          <Impressions mode="dark" />
          <Testimonials mode="dark" />
        </div>
      </div>
    </>
  );
}

export default DarkPage;
