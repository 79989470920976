/**
 * @file light.jsx
 * @description Light home page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import { Link } from "react-router-dom";
import Meta from "../components/Meta";
import MediumHero from "../components/mediumHero";
import Impressions from "../components/impressions";

/**
 * @function LightPage
 * @returns {JSX.Element} Light home page.
 */

function LightPage() {
  return (
    <>
      <Meta
        title="Light Version"
        description="Bright version of the glive style."
      />
      <MediumHero
        title="glive (Light)"
        subtitle="Bright version of the glive style."
        buttonText="Download"
        buttonLink="/download/light"
      />
      <div className="section">
        <div className="container">
        <article className="message is-dark">
            <div className="message-header">
              <p>Dark Mode</p>
            </div>
            <div className="message-body">
              <p>
                If you prefer a dark style, you can download the dark version of “glive” here:
              </p>
              <br/>
              <Link to="/dark" className="button is-primary">
                Download
              </Link>
            </div>
          </article>
          <Impressions mode="light"/>
        </div>
      </div>
    </>
  );
}

export default LightPage;
