/**
 * @file download.jsx
 * @description Download page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Meta from "../components/Meta";
import Hero from "../components/hero";
import FullheightHero from "../components/fullheightHero";
import { config } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import downloadFile from "../util/downloadFile";

/**
 * @function DownloadPage
 * @description Download page.
 * @returns {JSX.Element} Download page.
 */

function DownloadPage() {
  const app = useLocation().pathname.split("/")[2];

  config.autoAddCss = false;

  const [emailError, setEmailError] = useState(false);
  const [versionError, setVersionError] = useState(false);
  const [licenseError, setLicenseError] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    if (app === "light") {
      setVersions([
        {
          version: "1.0.0",
        },
      ]);
    } else if (app === "dark") {
      setVersions([
        {
          version: "1.0.0",
        },
      ]);
    } else {
      setVersions([]);
    }
  }, [app]);

  const submitForm = (e) => {
    e.preventDefault();
    if (e.target.email.value === "") {
      setEmailError(true);
    } else {
      setEmailError(false);
      if (new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(e.target.email.value)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
    if (
      e.target.version.value === "" ||
      e.target.version.value === "Select a version" ||
      e.target.version.value === "none"
    ) {
      setVersionError(true);
    } else {
      setVersionError(false);
    }
    if (!e.target.license.checked) {
      setLicenseError(true);
    } else {
      setLicenseError(false);
    }
    if (!e.target.privacy.checked) {
      setPrivacyError(true);
    } else {
      setPrivacyError(false);
    }
    if (emailError || versionError || licenseError || privacyError) {
      return;
    }
    if (
      e.target.email.value !== "" &&
      e.target.version.value !== "" &&
      e.target.license.checked
    ) {
      setEmailError(false);
      setVersionError(false);
      setLicenseError(false);
      setPrivacyError(false);
      downloadFile(e.target.email.value, e.target.version.value, app);
    }
  };

  if (versions.length === 0) {
    return (
      <>
        <Meta
          title="404"
          description="The page you are looking for does not exist."
          noindex={true}
        />
        <FullheightHero
          title="404"
          subtitle="The page you are looking for does not exist."
          buttonText="Go back to the homepage"
          buttonLink="/"
          color="is-danger"
        />
      </>
    );
  } else {
    return (
      <>
        <Meta
          title={
            "Download - " + app.charAt(0).toUpperCase() + app.slice(1)
          }
          description={
            "Download the latest version of glive: " +
            app.charAt(0).toUpperCase() +
            app.slice(1) +
            "."
          }
        />
        <div>
          <Hero
            title={
              "Download - glive: " + app.charAt(0).toUpperCase() + app.slice(1)
            }
            subtitle={
              "Download the latest version of glive: " +
              app.charAt(0).toUpperCase() +
              app.slice(1) +
              "."
            }
            color="is-primary"
          />
          <div className="section">
            <div className="tabs is-centered">
              <ul>
                <li {...(app === "light" ? { className: "is-active" } : {})}>
                  <Link to="/download/light">Light</Link>
                </li>
                <li {...(app === "dark" ? { className: "is-active" } : {})}>
                  <Link to="/download/dark">Dark</Link>
                </li>
              </ul>
            </div>
            <div className="container">
              <article className="message is-info">
                <div className="message-header">
                  <p>Information</p>
                </div>
                <div className="message-body">
                  <p>
                    Please fill out the form below to download the latest
                    version of the style. We don't send spam, we promise!
                  </p>
                </div>
              </article>
              <form onSubmit={submitForm}>
                <div className="field">
                  <label className="label" htmlFor="email">
                    Email <span className="customOptionRequired">*</span>
                  </label>
                  <div
                    className={`control has-icons-left ${
                      emailError ? "has-icons-right" : ""
                    }`}
                  >
                    <input
                      className={`input ${emailError ? "is-danger" : ""}`}
                      id="email"
                      type="email"
                    />
                    <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    {emailError && (
                      <span className="icon is-small is-right">
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                      </span>
                    )}
                  </div>
                  {emailError && (
                    <p className="help is-danger">This field is required</p>
                  )}
                </div>
                <div className="field">
                  <label className="label" htmlFor="version">
                    Version <span className="customOptionRequired">*</span>
                  </label>
                  <div className="control">
                    <div
                      className={`select ${versionError ? "is-danger" : ""}`}
                    >
                      <select defaultValue="none" id="version">
                        <option value="none" disabled>
                          Choose a version
                        </option>
                        {versions.map((version) => (
                          <option key={version.version} value={version.version}>
                            {version.version}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {versionError && (
                    <p className="help is-danger">This field is required</p>
                  )}
                </div>
                <hr />
                <div className="field">
                  <input
                    className={`is-checkradio ${
                      licenseError ? "has-background-color is-danger" : ""
                    }`}
                    type="checkbox"
                    id="license"
                  />
                  <label className="checkbox" htmlFor="license">
                    &nbsp;I agree to the{" "}
                    <Link to="/license">license agreement</Link>.{" "}
                    <span className="customOptionRequired">*</span>
                  </label>
                  {licenseError && (
                    <p className="help is-danger">This field is required</p>
                  )}
                </div>
                <div className="field">
                  <input
                    className={`is-checkradio ${
                      privacyError ? "has-background-color is-danger" : ""
                    }`}
                    type="checkbox"
                    id="privacy"
                  />
                  <label className="checkbox" htmlFor="privacy">
                    &nbsp;I have read and agree to the{" "}
                    <Link to="/privacy-policy">privacy policy</Link>.{" "}
                    <span className="customOptionRequired">*</span>
                  </label>
                  {privacyError && (
                    <p className="help is-danger">This field is required</p>
                  )}
                </div>
                <br />
                <div className="field is-grouped">
                  <div className="control">
                    <button className="button is-primary">Download now</button>
                  </div>
                  <div className="control">
                    <Link to="/" className="button is-primary is-light">
                      Cancel
                    </Link>
                  </div>
                </div>
                <p>
                  <span className="customOptionRequired">*</span> Required
                  fields
                </p>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DownloadPage;
